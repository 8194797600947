import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'

import Layout from '../components/Layout'

import whyDataOps from '../resources/images/dataops/whyDataOps.svg'
import DataOpsDoneWell from '../resources/images/dataops/DataOpsDoneWell_photo.svg'
import AbestPractise from '../resources/images/dataops/AbestPractise.svg'
import TopChallenges from '../resources/images/dataops/TopChallenges.svg'

import { navbarScrolling } from '../helpers/navbarScrolling'

const dataops = () => {
  const data = useStaticQuery(graphql`
    query DataOpsSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "DataOps" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  useEffect(() => {
    navbarScrolling()
  }, [])

  return (
    <Layout linkColor={'dark'} isTransparent={true}>
      <HeaderSEO pageSEO={pageSEO} />
      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap" style={{ height: '700px' }}>
            <div className="col-sm-8 col-12 col-xl-9 mt-5  justify-content-center align-self-center mobile-padding-reset mobile-margin-top mobile-padding-top mobile-padding-left mobile-padding-right">
              <div className="mobile-margin-reset">
                <h1 className="h3-like primary-text fw-800 text-uppercase f-17">
                  YOUR DATAOPS PLATFORM
                </h1>
                <h2 className="fw-600 mobile-padding-reset">
                  Data <i>is</i> your business
                </h2>
                <p className="hero-description-dark">
                  <span className="fw-600">
                    {' '}
                    It’s time to believe the hype,
                  </span>{' '}
                  every digital transformation needs a data transformation. Our
                  world has been disrupted by the Coronavirus, and this has
                  introduced a new urgency to reduce costs as well as discover
                  and build digital revenue streams. The difference between
                  thriving and surviving now hinges on our ability to make data
                  actionable to more than a few technical “a-listers”.
                </p>
                <p className="hero-description-dark">
                  The winners in this new race are working fewer weekends, and
                  investing in modern data platforms that enable real-time
                  decision-making.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-1">
        <div className="d-flex flex-wrap pb-5 mobile-margin-top align-items-center">
          <div className="col-md-6">
            <img src={whyDataOps} alt="Why DataOps" />
          </div>
          <div className="offset-1"></div>
          <div className="col-md-5 justify-content-center align-self-center mobile-margin-top">
            <h2>Why DataOps?</h2>
            <p>
              If modern DataOps platforms are the key to every digital
              transformation, why do so many data projects fail to meet
              objectives? How many projects have we witnessed never make it to
              production, or worse yet, make it and crash and burn? Most
              estimates record data project failure rates
              <a
                href="https://venturebeat.com/2019/07/19/why-do-87-of-data-science-projects-never-make-it-into-production/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                as high as 87%*
              </a>
              , because teams are up against the following challenges:
            </p>
            <span className="fw-600">*Venturebeat, 2019</span>
          </div>
        </div>

        <div className="d-flex flex-wrap mt-5  pb-5 pt-5 mobile-padding-reset mobile-margin-bottom">
          <div className="col-md-5 justify-content-center align-self-center">
            <h2>Your top challenges:</h2>
            <ul className="dataops-top-challenges-list">
              <li>
                <span>“What data do we have and what’s in it?”</span>
              </li>
              <li>
                <span>
                  <a href="/blog/2020/04/how-you-can-keep-your-real-time-data-operations-on-the-right-side-of-gdpr-apache-kafka/">
                    “Are my data flows GDPR compliant?”
                  </a>
                </span>
              </li>
              <li>
                <span>“How do I get my data project to production?”</span>
              </li>
              <li>
                <span>
                  “How do I integrate the data platform into our corporate
                  services?”
                </span>
              </li>
              <li>
                <span>
                  <a href="/blog/2020/03/apache-kafka-when-the-honeymoon-is-over/">
                    “How can I resource the skills to use this data technology?”
                  </a>
                </span>
              </li>
              <li>
                <span>“How do I make developers more productive?”</span>
              </li>
              <li>
                <span>
                  “How do I give business users access to their data safely?”
                </span>
              </li>
              <li>
                <span>“How do I share data ethically?”</span>
              </li>
              <li>
                <span>“How can I deploy data products consistently?”</span>
              </li>
              <li>
                <span>“Am I meeting my data quality SLAs?”</span>
              </li>
              <li>
                <span>
                  “How do I monitor the security posture of my data operations &
                  respond to threats?”
                </span>
              </li>
            </ul>
          </div>
          <div className="col-md-7 justify-content-center align-self-center">
            <img src={TopChallenges} alt="Your top challenges" />
          </div>
        </div>

        <div className="d-flex flex-wrap mt-5 pt-5 mobile-padding-reset mobile-margin-bottom">
          <div className="col-11 mobile-padding-left mobile-padding-right">
            <div className="justify-content-center align-self-center">
              <h3 className="primary-text text-uppercase fw-800 f-17">
                Dataops Definition
              </h3>
              <h2>Bringing Data, Apps & People Together.</h2>
            </div>

            <p>
              What is DataOps? The practice of Data Operations takes the best
              bits from DevOps, removes the human bottlenecks from data
              projects, decouples business decisions from the infrastructure,
              and makes data more accessible to the right people.
            </p>
            <p>
              This allows organizations to successfully deliver effective data
              experiences, and shift their focus to data-driven business
              outcomes and cross-team alignment.
            </p>
          </div>
        </div>

        <div className="d-flex flex-wrap pb-5 align-items-center mobile-margin-reset mt-5 pt-5 mobile-padding-reset">
          <div className="col-md-6 ">
            <img
              className="w-100"
              src={DataOpsDoneWell}
              alt="DataOps Outcomes"
            />
          </div>
          <div className="offset-1"></div>
          <div className="col-md-5 justify-content-center align-self-center">
            <h2>DataOps Outcomes</h2>
            <p>DataOps practices are designed to:</p>
            <ul className="red-checked-list">
              <li>
                <span>
                  <span className="fw-600 p-text">Increase productivity</span> -
                  More cool projects for engineering.
                </span>
              </li>
              <li>
                <span>
                  <span className="fw-600 p-text">
                    Fast track projects into production
                  </span>{' '}
                  - Dev’s best friend.
                </span>
              </li>
              <li>
                <span>
                  <span className="fw-600 p-text">
                    Lower the need for niche technical skills
                  </span>{' '}
                  - HR says thanks.
                </span>
              </li>
              <li>
                <span>
                  <span className="fw-600 p-text">Improve collaboration </span>-
                  Can’t we all get along? Yes.
                </span>
              </li>
              <li>
                <span>
                  <span className="fw-600 p-text">Eliminate tech lock-in</span>{' '}
                  - Happy CFOs? It’s possible.
                </span>
              </li>
              <li>
                <span>
                  <span className="fw-600 p-text">Fewer lost weekends</span> -
                  Happy developers, more time for gaming.
                </span>
              </li>
              <li>
                <span>
                  <span className="fw-600 p-text">Happier bosses</span> - Keep
                  the C-suite sweet.
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="d-flex flex-wrap mt-5  pb-5 pt-5 mobile-padding-reset mobile-margin-bottom align-items-center">
          <div className="col-md-5">
            <h3 className="primary-text fw-800 text-uppercase f-17">
              DataOps definition
            </h3>
            <h2>Key Capabilities</h2>
            <p>What are the DataOps components you should consider?</p>
            <ul className="red-checked-list">
              <li>
                <span>
                  {' '}
                  <span className="p-text fw-600">Data governance </span> -
                  Application & data catalog for automated discovery
                </span>
              </li>
              <li>
                <span>
                  <span className="p-text fw-600">
                    Data processing & integration
                  </span>{' '}
                  - Wrangle & move data with SQL
                </span>
              </li>
              <li>
                <span>
                  {' '}
                  <span className="p-text fw-600">Self-service</span>-
                  Decentralized operations with governance
                </span>
              </li>
              <li>
                <span>
                  {' '}
                  <span className="p-text fw-600">Security operations</span> -
                  Data access protected with authentication and auditing
                </span>
              </li>
              <li>
                <span>
                  {' '}
                  <span className="p-text fw-600">Visibility</span> - App
                  topology, data observability, monitoring & alerting
                </span>
              </li>
              <li>
                <span>
                  {' '}
                  <span className="p-text fw-600">Data compliance</span> - Data
                  lineage, data masking & PII data discovery
                </span>
              </li>
            </ul>
          </div>

          <div className="offset-md-1"></div>
          <div className="col-md-6">
            <img src={AbestPractise} alt="A best practice" />
          </div>
        </div>

        <div className="d-flex flex-wrap mt-5 pt-5 mobile-padding-reset mobile-margin-reset">
          <div className="col-md-12 justify-content-center align-self-center">
            <h3 className="primary-text text-uppercase fw-800 f-17">
              DATAOPS BEST PRACTICES
            </h3>
            <h2>The ingredients for DataOps success.</h2>
          </div>
        </div>

        <div className="d-flex flex-wrap mb-5 pb-5 mobile-margin-reset">
          <div className="col-sm-4 col-12">
            <ul className="red-checked-list">
              <li>
                <span>
                  Open communication between Development, IT and the business
                </span>
              </li>
              <li>
                <span>Transparent business metrics and measurement</span>
              </li>
              <li>
                <span>Access to any streaming and batch data</span>
              </li>
              <li>
                <span>Self-service operations</span>
              </li>
            </ul>
          </div>
          <div className="col-sm-4 col-12">
            <ul className="red-checked-list">
              <li>
                <span>Data observability</span>
              </li>
              <li>
                <span>Partnership with security operations</span>
              </li>
              <li>
                <span>Promotes data meshes</span>
              </li>
              <li>
                <span>
                  Easy integration and visualization of data pipelines
                </span>
              </li>
            </ul>
          </div>

          <div className="col-sm-4 col-12">
            <ul className="red-checked-list">
              <li>
                <span>
                  Integration with modern, data-intensive applications
                </span>
              </li>
              <li>
                <span>Reconciled data governance</span>
              </li>
              <li>
                <span>Transparency to promote data ethics</span>
              </li>
              <li>
                <span>
                  Democratized data access with privacy and ethics directives
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="mt-5 pt-5 dataops-customers-bg mobile-margin-reset mobile-padding-reset">
        <div className="text-center pt-5 mt-5 mobile-margin-reset mobile-padding-reset">
          <h3 className="primary-text pt-5 mt-5 fw-800 f-17 mobile-padding-reset">
            DATAOPS IN PRACTICE
          </h3>
          <h2 className="fw-600">Teams reaping the rewards</h2>
        </div>
        <div className="container-1">
          <div className="d-flex flex-wrap py-5 mobile-padding-reset">
            <div className="col-xl-4 px-1  col-12  mobile-margin-top-half mobile-margin-bottom mt-3">
              <div className="playtika-customer-bg align-items-center mobile-padding-reset">
                <div className="text-white fw-600  mobile-padding-left">
                  <p className="text-white">
                    “DataOps with Lenses.io has been critical in making Kafka
                    production-ready. The productivity gains we have made have
                    accelerated the delivery of new features and saved us
                    approximately 2 million Euros per year.”
                  </p>
                  – Ella Vidra, VP IT at Playtika
                  <a href="/customers/playtika/" className="customer-link">
                    Read Case Study &rarr;
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 px-1 col-12 mobile-margin-top-half mobile-margin-bottom mt-3">
              <div className="vortexa-customer-bg align-items-center mobile-padding-reset">
                <div className="text-white fw-600 mobile-padding-left">
                  <p className="text-white">
                    “Kafka and Kafka Streams are powerful “black-boxes”. Limited
                    visibility has led to unpredictable development times.
                    DataOps, with Lenses.io, has created unparalleled visibility
                    into our Kafka infrastructure, helping us reduce development
                    times and build more confidence in R&D”
                  </p>
                  – Maksym Schipka, CTO, Vortexa
                  <a href="/customers/vortexa/" className="customer-link">
                    Read Case Study &rarr;
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 px-1 col-12 mobile-margin-top-half mobile-margin-bottom mt-3">
              <div className="riskfuel-customer-bg align-items-center  mobile-padding-reset">
                <div className="text-white fw-600 mobile-padding-left">
                  <p className="text-white">
                    “The more time our data scientists spend focused on building
                    models and delivering products, the more money we generate
                    to the business. Lenses is crucial for us in this area,
                    helping make our data scientists more productive and
                    valuable.”
                  </p>
                  – Ryan Fergusson, CEO, Riskfuel
                  <a href="/customers/riskfuel/" className="customer-link">
                    Read Case Study &rarr;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="row">
        <div className="section-hero-451 col-12"></div>
      </div>

      <section className="bg-red p-5">
        <div className="container">
          <div className="row p-2">
            <div className="col-md-12 col-12 text-center roll-button">
              <p className=" text-white paragraph-title pb-3">
                Get a Guided DataOps Tour
              </p>
              <a className="bg-white primary-text" href="/contact/lenses-demo/">
                <span data-hover="Request demo">Request demo</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default dataops
